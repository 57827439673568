import Vue from 'vue'
import Vuex from 'vuex'

import afterImport from './modules/after-import'
import auth from './modules/auth'
import cacheWatcher from './modules/cache-watcher'
import classes from './modules/classes'
import config from './modules/config'
import constants from './modules/constants'
import endpoints from './modules/endpoints'
import favorite from './modules/favorite'
import lang from './modules/lang'
import license from './modules/license'
import notifications from './modules/notifications'
import ontologySettings from './modules/ontology-settings'
import ontologyVersions from './modules/ontology-versions'
import proposed from './modules/proposed'
import prefixes from './modules/prefixes'

Vue.use(Vuex)

class State {} //NOSONAR

export default new Vuex.Store({
	state: new State(),
	modules: {
		afterImport,
		auth,
		cacheWatcher,
		classes,
		config,
		constants,
		endpoints,
		favorite,
		lang,
		license,
		notifications,
		proposed,
		prefixes,
		ontologySettings,
		ontologyVersions
	},
})
