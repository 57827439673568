<template>
	<div class="toast">
		<div class="title" v-if="title">
			{{ title }}
		</div>
		<div class="content">
			<div class="text">
				{{ content }}
			</div>
			<div class="copy" v-if="copy">
				<a class="tooltip" @click="_copy">
					<TheCopy class="black" :width="20" :height="20"/>
				</a>
			</div>
		</div>
	</div>
</template>

<script>
import TheCopy from "@/components/Svg/Copy";
import {keyErrors} from "@/notifications";
import {Language} from "@/constants";
import {getUiLang} from "@/utils/lang";
import {toast} from "@/store/modules/notifications";

export default {
	name: "Toast",
	components: {
		TheCopy,
	},
	data() {
		return {
			lang: {
				[Language.ru]: {
					success: 'Успешно',
					fail: 'Не удалось скопировать в буффер.',
					copy: 'Копировать'
				},
				[Language.en]: {
					success: 'Success',
					fail: 'Failed to copy to the buffer.',
					copy: 'Copy'
				},
			}
		}
	},
	props: {
		title: {
			type: String,
			require: false
		},
		content: {
			type: String
		},
		copy: {
			type: Boolean,
			default: true
		}
	},
	methods: {
		async _copy() {
			if (navigator.clipboard && window.isSecureContext) {
				await navigator.clipboard.writeText(this.content)
			} else {
				const textarea = document.createElement('textarea')
				textarea.value = this.content

				textarea.style.position = 'absolute'
				textarea.style.left = '-99999999px'

				document.body.prepend(textarea)

				textarea.select()
				try {
					document.execCommand('copy')
				} catch (err) {
					toast({id: keyErrors.CopyToBuffer, arg: this.lang[getUiLang().toLowerCase()].fail})
				} finally {
					textarea.remove()
				}
			}
			toast({id: keyErrors.CopyToBuffer, arg: this.lang[getUiLang().toLowerCase()].success})
		},

	}
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/colors.css';

.toast {
	width: 360px;

	.title {
		border-bottom: 1px solid #dfdfdf;
		padding: 7px 10px;
	}

	.content {
		display: flex;
		align-items: center;
		padding: 10px;
		gap: 10px;
		.text {
			display: grid;
			align-content: center;
		}

		.tooltip {
			display: grid;

		}
	}

}

</style>